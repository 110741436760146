import React from "react";

import { Link } from "react-router-dom";
function ServiceDetailsWrap() {
  return (
    <>
      <div className="service-section2 pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center gy-5">
            <div className="col-lg-5 col-md-5">
              <div
                className="section-card service-details-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>House Design</h2>
                <p>
                  Personalised house designs that reflect your unique lifestyle and preferences.
                </p>
                <p>
                  Creating spaces that inspire, energize, and enhance your daily life.
                </p>
                {/* <Link to={"#"} class="eg-btn hover-btn sectoin-card-btn">
                  <span>
                    Download Profile{" "}
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"
                      }
                      alt="images"
                    />
                  </span>
                </Link> */}
              </div>
              <div
                className="hire-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h3>DO YOU HAVE A PROJECT IN YOUR MIND</h3>
                <div className="eg-btn hire-btn hover-btn">
                  <span>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                      Contact Us Now
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"
                        }
                        alt="images"
                      />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="section-title text-center">
                <h2>Innovative Design</h2>
              </div>
              <div className="service-details-area">
                <div className="service-details-img">
                  <img
                    src={process.env.PUBLIC_URL + "/images/bg/sdetails1.png"}
                    alt="images"
                  />
                </div>
                <div className="service-details-content">
                  <h3>House Design</h3>
                  <p className="para">
                    Our house design service offers comprehensive and tailored solutions that reflect our clients' needs and aspirations. From the initial consultation to the construction phase, our experienced team of architects and designers works closely with clients to develop innovative and functional designs that incorporate the latest technologies and sustainable practices. With a focus on quality, innovation, and client satisfaction, we aim to deliver exceptional results that exceed our clients' expectations and enhance their living experience.
                  </p>
                  <h4>Service Intro</h4>
                  <ul>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Initial Consultation
                    </li>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Site Analysis
                    </li>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Concept Design
                    </li>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Development Design
                    </li>

                  </ul>
                  {/* <div className="row g-3 service-intro-row">
                    <div className="col-md-6 col-sm-6">
                      <img
                        src={
                          process.env.PUBLIC_URL + " /images/bg/sdetails2.png"
                        }
                        alt="images"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <h6>
                        When it comes to the tech industry, there are many
                        different paths you can follow.
                      </h6>
                      <p className="para">
                        UX design, UI design, web development, data analytics,
                        and digital marketing all offer exciting, fulfilling,
                        and financially rewarding careers—but each role is
                        unique. So, before you decide on a particular field,
                        it’s important to understand exactly what each role
                        entails.
                      </p>
                    </div>
                  </div> */}
                </div>
                {/* <div className="work-process">
                  <h3>Work Process</h3>
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp1.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Research</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp2.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Idea</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp3.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Develop</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp4.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Launch</Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center gy-5">
            <div className="col-lg-5 col-md-5">
              <div
                className="section-card service-details-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Development Project Management</h2>
                <p>
                  Expert management for successful property development projects.
                </p>
                <p>
                  Efficiency, reliability, and excellence in property development project management.
                </p>
                {/* <Link to={"#"} class="eg-btn hover-btn sectoin-card-btn">
                  <span>
                    Download Profile{" "}
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"
                      }
                      alt="images"
                    />
                  </span>
                </Link> */}
              </div>
              <div
                className="hire-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h3>DO YOU HAVE A PROJECT IN YOUR MIND</h3>
                <div className="eg-btn hire-btn hover-btn">
                  <span>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                      Contact Us Now
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"
                        }
                        alt="images"
                      />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="section-title text-center">
                <h2>Experienced, dedicated, and results-driven</h2>
              </div>
              <div className="service-details-area">
                <div className="service-details-img">
                  <img
                    src={process.env.PUBLIC_URL + "/images/bg/sdetails2.png"}
                    alt="images"
                  />
                </div>
                <div className="service-details-content">
                  <h3>Project Management</h3>
                  <p className="para">
                    As a house design company, we understand that building a successful project requires more than just designing it. That's why we offer project development and construction management services to ensure that the project is built to the highest standards, on time and on budget.
                  </p>
                  <p>
                    Our team of experienced project managers and construction managers work closely with clients to oversee all aspects of the project, from site preparation and foundation work to finishing touches and final inspections. We carefully select and manage contractors and subcontractors, and ensure that all work is done in accordance with the design specifications and building codes.
                  </p>
                  <p>
                    In addition, our designers and architects work closely with our construction team to ensure that the design intent is fully realized during the construction process. This includes reviewing and approving shop drawings, submittals, and other construction documents, as well as conducting site visits and inspections to ensure that the work is being done to the highest standards.
                  </p>
                  <p>
                    By combining our design expertise with our project development and construction management services, we are able to provide a seamless and integrated approach that ensures the successful delivery of each project. Our goal is to exceed our clients' expectations by delivering exceptional quality, innovation, and value, and by building lasting relationships based on trust, transparency, and mutual respect.
                  </p>
                  <h4>Service Intro</h4>
                  <ul>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Construction Documentation
                    </li>
                    <li style={{ color: "white" }}>
                      <img src={process.env.PUBLIC_URL + "/images/icon/arrow-dark.svg"} alt="images" style={{ width: 30 }} /> Construction Administration
                    </li>

                  </ul>

                </div>
                {/* <div className="work-process">
                  <h3>Work Process</h3>
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp1.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Research</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp2.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Idea</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp3.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Develop</Link>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-6">
                      <div className="work-pro-item">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon/workp4.svg"
                          }
                          alt="images"
                        />
                        <h4>
                          <Link to={"#"}>Launch</Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
