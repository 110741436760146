import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
function Footer() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-top-area">
            <div className="row d-flex justify-content-sm-start justify-content-center gy-5">
              <div className="col-lg-5 col-md-6 text-sm-start">
                <div className="footer-left-address">
                  <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/logo.png"}
                      className="footer-logo"
                      alt="images"
                    />
                  </Link>
                  <ul className="address-list">
                    <li>
                      <li>
                        <a href="tel:+61426700961">
                          Phone: +61 (0) 426700961
                        </a>
                      </li>
                      <Link
                        onClick={scrollTop}
                        to="mailto:info@ana.homes"
                      >
                        Email: info@ana.homes
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        Address: U2 191 Melbourne St, North Adelaide, SA 5006
                      </Link>
                    </li>

                  </ul>
                  {/* <div className="newsletter-area">
                    <h5>Get The Updates</h5>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-inner">
                        <input type="text" placeholder="Type your Email :" />
                        <Link
                          onClick={scrollTop}
                          to={"#"}
                          className="newsletter-btn hover-btn"
                        >
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icon/nletter-arrow.svg"
                              }
                              alt="images"
                            />
                          </span>
                        </Link>
                      </div>
                      <p>We only send interesting and relevant emails.</p>
                    </form>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="footer-right-area">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Explore On</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/`}
                              onClick={scrollTop}
                            >
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/contact-us`}
                              onClick={scrollTop}
                            >
                              Contact
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/feedback`}
                              onClick={scrollTop}
                            >
                              Feedback
                            </Link>
                          </li>
                          <li>
                            <Link to="#">Agency Minimal</Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Our Services</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              Home Designs
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              House & Land
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              Build & Rebuild
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              Maintain
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service-details`}
                              onClick={scrollTop}
                            >
                              Management
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div className="row">
              <div className="col-lg-5 text-lg-start text-center">
                <div className="footer-bottom-left">
                  <a
                    onClick={scrollTop}
                    rel="noopener noreferrer"
                    href="https://www.egenslab.com/"
                    target="_blank"
                  >
                    <h6 className="mb-0">
                      Copyright 2023 <span>Quaintquant PTY LTD</span> trading as  <span>ANA Homes</span>.
                    </h6>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-7 text-lg-end text-center">
                <div className="footer-bottom-right">
                  <Link onClick={scrollTop} to={"#"}>
                    <h6 className="mb-0">Privacy Policy | Terms of Use</h6>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="scroll-btn show">
        <ScrollButton></ScrollButton>
      </div>
    </>
  );
}
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <i
      className="bx bxs-up-arrow-circle"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    ></i>
  );
};

export default Footer;
