import React from "react";
import { Link } from "react-router-dom";
function ShowReactArea() {
  return (
    <>
      <div className="post-react-area text-md-start text-center">
        <h5>Show Your React</h5>
        <ul className="d-flex justify-content-md-start justify-content-center align-items-center flex-wrap">
          <li>
            <Link to={"#"} className="emoji">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.0008 46.0417C13.3985 46.0417 3.95898 36.6022 3.95898 24.9999C3.95898 13.3975 13.3985 3.95801 25.0008 3.95801C36.6032 3.95801 46.0427 13.3975 46.0427 24.9999C46.0427 36.6022 36.6032 46.0417 25.0008 46.0417ZM25.0008 5.35336C14.1671 5.35336 5.35433 14.1661 5.35433 24.9999C5.35433 35.8336 14.1671 44.6464 25.0008 44.6464C35.8346 44.6464 44.6474 35.8336 44.6474 24.9999C44.6474 14.1661 35.8346 5.35336 25.0008 5.35336Z" />
                <path d="M13.1913 18.5768C13.0192 18.5768 12.8472 18.514 12.7123 18.3861C12.432 18.1209 12.4204 17.6791 12.6855 17.4C12.8146 17.264 15.8948 14.0663 19.7413 14.7989C20.1204 14.8709 20.3681 15.2361 20.296 15.6151C20.2239 15.9942 19.8576 16.2419 19.4797 16.1698C16.3739 15.5779 13.7251 18.3314 13.6983 18.3593C13.5611 18.5035 13.3762 18.5768 13.1913 18.5768Z" />
                <path d="M36.9862 18.5767C36.8014 18.5767 36.6176 18.5035 36.4804 18.3593C36.4444 18.3221 33.7816 15.5814 30.6979 16.1686C30.3188 16.2407 29.9537 15.9919 29.8816 15.6139C29.8095 15.236 30.0583 14.8698 30.4362 14.7977C34.2827 14.0651 37.363 17.2616 37.4921 17.3988C37.7572 17.6791 37.7444 18.1198 37.4653 18.3849C37.3304 18.514 37.1583 18.5767 36.9862 18.5767Z" />
                <path d="M24.8735 38.0626C23.877 38.0626 22.9933 37.9254 22.2526 37.7394C19.9293 37.1568 18.5014 35.9964 18.4421 35.9475C18.1444 35.7022 18.1014 35.2627 18.3468 34.965C18.5909 34.6685 19.0293 34.6243 19.327 34.8685C19.3398 34.879 20.6142 35.9022 22.6514 36.3999C25.3549 37.0592 28.0735 36.5289 30.7317 34.8208C31.0561 34.6127 31.4875 34.7069 31.6956 35.0313C31.9037 35.3557 31.8096 35.7871 31.4851 35.9952C29.0305 37.5731 26.7537 38.0626 24.8735 38.0626Z" />
                <path d="M17.5311 20.3254C16.4671 20.3254 15.6055 21.1871 15.6055 22.251C15.6055 23.315 16.4671 24.1766 17.5311 24.1766C18.595 24.1766 19.4566 23.315 19.4566 22.251C19.4566 21.1871 18.595 20.3254 17.5311 20.3254Z" />
                <path d="M32.6092 20.3254C31.5452 20.3254 30.6836 21.1871 30.6836 22.251C30.6836 23.315 31.5452 24.1766 32.6092 24.1766C33.6731 24.1766 34.5347 23.315 34.5347 22.251C34.5347 21.1871 33.672 20.3254 32.6092 20.3254Z" />
              </svg>
              <h6>50K</h6>
            </Link>
          </li>
          <li>
            <Link to={"#"} className="emoji">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.0008 46.0417C13.3985 46.0417 3.95898 36.6022 3.95898 24.9999C3.95898 13.3975 13.3985 3.95801 25.0008 3.95801C36.6032 3.95801 46.0427 13.3975 46.0427 24.9999C46.0427 36.6022 36.6032 46.0417 25.0008 46.0417ZM25.0008 5.35336C14.1671 5.35336 5.35433 14.1661 5.35433 24.9999C5.35433 35.8336 14.1671 44.6464 25.0008 44.6464C35.8346 44.6464 44.6474 35.8336 44.6474 24.9999C44.6474 14.1661 35.8346 5.35336 25.0008 5.35336Z" />
                <path d="M13.3085 18.4605C13.1364 18.4605 12.9643 18.3977 12.8295 18.2698C12.5492 18.0047 12.5376 17.5629 12.8027 17.2838C12.9318 17.1477 16.012 13.9512 19.8585 14.6826C20.2376 14.7547 20.4853 15.1198 20.4132 15.4989C20.3411 15.878 19.9748 16.1256 19.5969 16.0535C16.4911 15.4617 13.8423 18.2152 13.8155 18.2431C13.6783 18.3873 13.4934 18.4605 13.3085 18.4605Z" />
                <path d="M37.1023 18.4605C36.9174 18.4605 36.7337 18.3873 36.5965 18.2431C36.5523 18.1966 33.8918 15.4663 30.815 16.0524C30.436 16.1245 30.0709 15.8756 29.9988 15.4977C29.9267 15.1198 30.1755 14.7536 30.5534 14.6815C34.3999 13.9489 37.4802 17.1454 37.6093 17.2826C37.8744 17.5628 37.8616 18.0035 37.5825 18.2687C37.4465 18.3966 37.2744 18.4605 37.1023 18.4605Z" />
                <path d="M17.6841 24.8839C18.8105 24.8839 19.7236 23.6136 19.7236 22.0467C19.7236 20.4797 18.8105 19.2095 17.6841 19.2095C16.5577 19.2095 15.6445 20.4797 15.6445 22.0467C15.6445 23.6136 16.5577 24.8839 17.6841 24.8839Z" />
                <path d="M32.7231 24.8839C33.8495 24.8839 34.7627 23.6136 34.7627 22.0467C34.7627 20.4797 33.8495 19.2095 32.7231 19.2095C31.5967 19.2095 30.6836 20.4797 30.6836 22.0467C30.6836 23.6136 31.5967 24.8839 32.7231 24.8839Z" />
                <path d="M25.1039 41.2603C27.0922 41.2603 28.7039 38.6365 28.7039 35.3998C28.7039 32.1631 27.0922 29.5393 25.1039 29.5393C23.1157 29.5393 21.5039 32.1631 21.5039 35.3998C21.5039 38.6365 23.1157 41.2603 25.1039 41.2603Z" />
              </svg>
              <h6>25.5 K</h6>
            </Link>
          </li>
          <li>
            <Link to={"#"} className="emoji">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24.9996 46.0465C13.395 46.0465 3.95312 36.6047 3.95312 25C3.95312 13.3954 13.395 3.95349 24.9996 3.95349C36.6043 3.95349 46.0461 13.3954 46.0461 25C46.0461 36.6047 36.6043 46.0465 24.9996 46.0465ZM24.9996 5.34884C14.1624 5.34884 5.34847 14.1628 5.34847 25C5.34847 35.8372 14.1624 44.6512 24.9996 44.6512C35.8368 44.6512 44.6508 35.8372 44.6508 25C44.6508 14.1628 35.8368 5.34884 24.9996 5.34884Z" />
                <path d="M25.4293 29.686C21.6851 29.686 19.1735 31.8488 18.8828 35.0232V35.4186C18.8828 35.7325 19.1386 35.9767 19.441 35.9767H31.4177C31.7317 35.9767 31.9758 35.7209 31.9758 35.4186V35.0232C31.7549 31.7907 29.4758 29.686 25.4293 29.686Z" />
                <path d="M34.9768 19.1161C34.8605 19.1161 34.7326 19.0812 34.6279 19.0231C32.7326 17.9185 30.8605 15.5347 30.7907 15.4301C30.5582 15.1277 30.6047 14.6859 30.9186 14.4533C31.221 14.2208 31.6628 14.2673 31.8954 14.5812C31.9186 14.6045 33.6745 16.8603 35.3373 17.8254C35.6745 18.0231 35.7791 18.4417 35.5931 18.7789C35.4419 18.9998 35.2093 19.1161 34.9768 19.1161Z" />
                <path d="M17.1865 29.3372C17.1516 29.3372 17.1167 29.3372 17.0818 29.3256C16.6981 29.2675 16.4423 28.907 16.5004 28.5233C16.5818 27.9768 16.6981 25.1512 16.7446 23.3837C16.7562 23 17.0818 22.6977 17.4655 22.7093C17.8493 22.7209 18.1516 23.0465 18.1399 23.4302C18.1283 23.8837 18.012 27.9303 17.8841 28.7558C17.8144 29.093 17.5237 29.3372 17.1865 29.3372Z" />
                <path d="M15.6975 39.2559C15.5929 39.2559 15.4882 39.2327 15.3952 39.1861C15.0464 39.0234 14.9068 38.6048 15.0696 38.2559C15.2906 37.8024 15.6161 35.4885 15.8138 33.5117C15.8487 33.128 16.1975 32.8489 16.5813 32.8838C16.965 32.9187 17.2441 33.2675 17.2092 33.6512C17.1394 34.3606 16.7673 37.9652 16.3371 38.8722C16.2092 39.1164 15.965 39.2559 15.6975 39.2559Z" />
                <path d="M33.3009 29.3371C32.952 29.3371 32.6381 29.0697 32.6032 28.7092C32.5916 28.5813 32.3125 25.6743 32.3125 23.4069C32.3125 23.0232 32.6265 22.7092 33.0102 22.7092C33.3939 22.7092 33.7078 23.0232 33.7078 23.4069C33.7078 25.6046 33.9985 28.5464 33.9985 28.5697C34.0334 28.9534 33.7544 29.2906 33.3706 29.3371C33.3358 29.3371 33.3125 29.3371 33.3009 29.3371Z" />
                <path d="M34.6398 39.2559C34.3491 39.2559 34.0816 39.0814 33.977 38.7907C33.6746 37.907 33.163 34.1047 33.1049 33.6745C33.0583 33.2907 33.3258 32.9419 33.7095 32.8954C34.0932 32.8489 34.4421 33.1163 34.4886 33.5C34.6979 35.0698 35.0932 37.7791 35.2909 38.3372C35.4188 38.6977 35.2211 39.0931 34.8607 39.221C34.7909 39.2442 34.7095 39.2559 34.6398 39.2559Z" />
                <path d="M17.6402 24.0814C19.053 24.0814 20.1983 22.936 20.1983 21.5232C20.1983 20.1104 19.053 18.9651 17.6402 18.9651C16.2273 18.9651 15.082 20.1104 15.082 21.5232C15.082 22.936 16.2273 24.0814 17.6402 24.0814Z" />
                <path d="M32.7085 24.0814C34.1214 24.0814 35.2667 22.936 35.2667 21.5232C35.2667 20.1104 34.1214 18.9651 32.7085 18.9651C31.2957 18.9651 30.1504 20.1104 30.1504 21.5232C30.1504 22.936 31.2957 24.0814 32.7085 24.0814Z" />
                <path d="M15.3945 19.1162C15.1503 19.1162 14.9177 18.9883 14.7898 18.7673C14.5922 18.4301 14.7084 18.0115 15.0456 17.8138C16.7084 16.8487 18.4643 14.5929 18.4875 14.5696C18.7201 14.2673 19.1619 14.2092 19.4643 14.4417C19.7666 14.6743 19.8247 15.1162 19.5922 15.4185C19.5108 15.5231 17.6503 17.9069 15.755 19.0115C15.6387 19.0929 15.5224 19.1162 15.3945 19.1162Z" />
              </svg>
              <h6>45K</h6>
            </Link>
          </li>
          <li>
            <Link to={"#"} className="emoji">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.0008 46.0418C13.3985 46.0418 3.95898 36.6023 3.95898 25C3.95898 13.3977 13.3985 3.95813 25.0008 3.95813C36.6032 3.95813 46.0427 13.3977 46.0427 25C46.0427 36.6023 36.6032 46.0418 25.0008 46.0418ZM25.0008 5.35348C14.1671 5.35348 5.35433 14.1663 5.35433 25C5.35433 35.8337 14.1671 44.6465 25.0008 44.6465C35.8346 44.6465 44.6474 35.8337 44.6474 25C44.6474 14.1663 35.8346 5.35348 25.0008 5.35348Z" />
                <path d="M34.2685 27.4104L30.6941 28.1895C30.6871 28.2534 30.6836 28.3174 30.6836 28.3837C30.6836 29.4476 31.5452 30.3092 32.6092 30.3092C33.6731 30.3092 34.5348 29.4476 34.5348 28.3837C34.5348 28.0278 34.4359 27.6964 34.2685 27.4104Z" />
                <path d="M15.8717 27.4104C15.7031 27.6953 15.6055 28.0278 15.6055 28.3825C15.6055 29.4465 16.4671 30.3081 17.5311 30.3081C18.595 30.3081 19.4566 29.4465 19.4566 28.3825C19.4566 28.3174 19.4531 28.2523 19.4462 28.1883L15.8717 27.4104Z" />
                <path d="M31.678 37.0721C31.6489 37.028 31.607 36.9838 31.5535 36.9407C31.5466 36.9349 31.5396 36.9291 31.5326 36.9233C31.528 36.9198 31.5233 36.9163 31.5175 36.9128C31.4733 36.8791 31.4268 36.85 31.3768 36.8256C30.507 36.3489 28.107 36.0059 25.2803 36.0059C22.4535 36.0059 20.0535 36.3489 19.1838 36.8256C19.1338 36.85 19.0873 36.8791 19.0431 36.9128C19.0384 36.9163 19.0338 36.9198 19.028 36.9233C19.021 36.9291 19.014 36.9349 19.007 36.9407C18.9535 36.9838 18.9117 37.028 18.8826 37.0721C18.7733 37.2152 18.707 37.3931 18.707 37.5873C18.707 38.0559 19.0861 38.4349 19.5547 38.4349H31.0035C31.4721 38.4349 31.8512 38.0559 31.8512 37.5873C31.8535 37.3931 31.7873 37.2152 31.678 37.0721Z" />
                <path d="M20.5576 26.9721C20.5088 26.9721 20.4588 26.9674 20.4088 26.9558L12.6809 25.2825C12.3041 25.2011 12.0646 24.829 12.1471 24.4535C12.2285 24.0767 12.6006 23.8383 12.9762 23.9197L20.7041 25.593C21.0809 25.6744 21.3204 26.0465 21.2378 26.4221C21.1681 26.7488 20.8785 26.9721 20.5576 26.9721Z" />
                <path d="M29.6172 26.972C29.2963 26.972 29.0067 26.7488 28.9358 26.422C28.8544 26.0453 29.0939 25.6743 29.4695 25.5929L37.1974 23.9197C37.5753 23.8383 37.9451 24.0778 38.0265 24.4534C38.1079 24.8302 37.8684 25.2011 37.4928 25.2825L29.7649 26.9557C29.716 26.9662 29.666 26.972 29.6172 26.972Z" />
              </svg>
              <h6>35K</h6>
            </Link>
          </li>
          <li>
            <Link to={"#"} className="emoji">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.0008 46.0418C13.3985 46.0418 3.95898 36.6023 3.95898 25C3.95898 13.3977 13.3985 3.95813 25.0008 3.95813C36.6032 3.95813 46.0427 13.3977 46.0427 25C46.0427 36.6023 36.6032 46.0418 25.0008 46.0418ZM25.0008 5.35348C14.1671 5.35348 5.35433 14.1663 5.35433 25C5.35433 35.8337 14.1671 44.6465 25.0008 44.6465C35.8346 44.6465 44.6474 35.8337 44.6474 25C44.6474 14.1663 35.8346 5.35348 25.0008 5.35348Z" />
                <path d="M13.1913 18.4606C13.0192 18.4606 12.8472 18.3978 12.7123 18.2699C12.432 18.0047 12.4204 17.5629 12.6855 17.2838C12.8146 17.1478 15.8948 13.9501 19.7413 14.6826C20.1204 14.7547 20.3681 15.1199 20.296 15.4989C20.2239 15.878 19.8576 16.1257 19.4797 16.0536C16.3739 15.4617 13.7251 18.2152 13.6983 18.2431C13.5611 18.3873 13.3762 18.4606 13.1913 18.4606Z" />
                <path d="M36.9862 18.4605C36.8013 18.4605 36.6176 18.3873 36.4804 18.2431C36.4444 18.2059 33.7816 15.4652 30.6979 16.0524C30.3188 16.1245 29.9537 15.8756 29.8816 15.4977C29.8095 15.1198 30.0583 14.7536 30.4362 14.6815C34.2827 13.9489 37.363 17.1454 37.492 17.2826C37.7572 17.5629 37.7444 18.0035 37.4653 18.2687C37.3304 18.3977 37.1583 18.4605 36.9862 18.4605Z" />
                <path d="M19.4598 35.0942C19.2481 35.0942 19.0388 34.9989 18.9028 34.8175C18.6714 34.5105 18.7307 34.0745 19.0365 33.8419C19.0888 33.8024 20.3516 32.8559 22.4481 32.3733C24.3667 31.9315 27.3911 31.8024 30.9016 33.7001C31.2411 33.8838 31.3667 34.307 31.183 34.6454C30.9993 34.9849 30.576 35.1105 30.2377 34.9268C27.7493 33.5814 25.2551 33.1745 22.8249 33.7175C21.0005 34.1245 19.89 34.9454 19.8784 34.9535C19.7528 35.0489 19.6051 35.0942 19.4598 35.0942Z" />
                <path d="M17.5311 20.2092C16.4671 20.2092 15.6055 21.0709 15.6055 22.1348C15.6055 22.6836 15.8357 23.1778 16.2043 23.5278H18.8578C19.2264 23.1767 19.4566 22.6836 19.4566 22.1348C19.4566 21.0709 18.595 20.2092 17.5311 20.2092Z" />
                <path d="M32.6092 20.2092C31.5452 20.2092 30.6836 21.0709 30.6836 22.1348C30.6836 22.6836 30.9138 23.1778 31.2824 23.5278H33.9359C34.3045 23.1767 34.5348 22.6836 34.5348 22.1348C34.5348 21.0709 33.672 20.2092 32.6092 20.2092Z" />
              </svg>
              <h6>53K</h6>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default ShowReactArea;
